<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
      <div class="flex flex-wrap items-center col-span-1">
        <h2 class="mr-3 text-2xl font-bold">Inactive users</h2>
        <router-link :to="{ name: 'users-new' }" class="text-sm text-gray-700"
          >+ Add new user</router-link
        >
      </div>
       <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, status, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>

    <div class="border border-blue-200 border-solid">
      <div class="flex">
        <div
          class="duration-300"
          :class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
        >
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :reverse-index="true"
            :data="users.data"
            :columns="columns"
            :pageDetails="true"
            dropdown="actions"
            :filters="filters"
            :onClick="click"
            :query="searchQuery"
            :className="tableRowClassName"
            :loading="users.loading"
            ref="table"
          />
        </div>
        <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
          <div class="h-full overflow-y-auto">
            <div class="animated animation-delay-300 fadeIn">
              <div class="mb-5">
                <div class="flex flex-row items-center justify-between mb-1">
                  <div class="flex flex-row items-center">
                    <h2 class="mr-2 text-lg font-bold">
                      {{ selectedUser.name | sentenceCase }}
                      {{ selectedUser.last_name | sentenceCase }}
                    </h2>
                  </div>
                  <div class="ml-auto dropdown">
                    <button
                      type="button"
                      class="flex flex-col focus:outline-none"
                      dropdown
                    >
                      <div class="w-1 h-1 mb-1 bg-gray-400 rounded-full"></div>
                      <div class="w-1 h-1 mb-1 bg-gray-400 rounded-full"></div>
                      <div class="w-1 h-1 bg-gray-400 rounded-full"></div>
                    </button>
                    <ul class="dropdown-menu">
                      <a
                        href="#!"
                        class="text-red-500 dropdown-menu-item"
                        @click.prevent="confirmDelete"
                        >Delete</a
                      >
                    </ul>
                  </div>
                </div>
                <div class="text-xs font-hairline text-gray-500">
                  {{ position | sentenceCase }}
                </div>
                <router-link
                  :to="{
                    name: 'staff-view',
                    params: { staffId: selectedUser.id },
                  }"
                  class="inline-flex items-center text-sm text-blue-500"
                >
                  <span>View Profile</span>
                </router-link>
              </div>

              <transition name="fade" mode="out-in">
                <div class="h-100px" v-if="userApprovalLoading">
                  <sm-loader />
                </div>

                <div v-else>
                  <!-- Profile Not Completed -->
                  <div class="mb-10" v-if="!userProfileCompleted">
                    <div class="mb-5 alert alert-blue-soft">
                      <span class="alert-icon">!</span>
                      <span
                        >{{ userFirstName }} hasn't completed their
                        profile.</span
                      >
                    </div>
                  </div>
                  <!-- Profile Not Completed -->

                  <!-- Profile Completed -->
                  <template v-else>
                    <div class="text-xs">
                      <template v-if="userApprovalCompleted">
                        <div class="mb-5 alert alert-green-soft">
                          <span class="alert-icon">!</span>
                          <div class="text-xs font-normal">
                            <template
                              v-if="
                                userApproval &&
                                  userApproval.action == 'activate'
                              "
                            >
                              Profile activated successfully.
                            </template>
                            <template v-else>
                              Profile deactivated successfully.
                            </template>
                          </div>
                        </div>
                      </template>

                      <template v-else-if="userApprovalPending">
                        <div class="mb-5 alert alert-blue-soft">
                          <span class="alert-icon">!</span>
                          <span
                            >{{ userFirstName }}'s profile needs
                            activation.</span
                          >
                        </div>
                      </template>
                    </div>
                  </template>
                  <!-- Profile Completed -->
                </div>
              </transition>

              <div
                class="grid grid-cols-1 mb-8 border border-blue-200 xl:grid-cols-2"
              >
                <div class="col-span-1">
                  <div class="relative px-8 py-8 border-blue-200 xl:px-12">
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-green-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Salary</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 font-black text-green-500 border-2 border-green-500 border-solid rounded-full"
                      >
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      ₦
                      {{
                        selectedUser.profile
                          ? selectedUser.profile.salary
                          : 0 | currency
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12 xl:border-t-0 xl:border-l"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-blue-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Position</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-sm font-black text-blue-500 border border-blue-500 border-solid rounded-full"
                      >
                        <ion-icon name="briefcase-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      {{
                        selectedUser.profile
                          ? selectedUser.profile.position
                          : "---"
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-blue-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Phone Number</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-sm font-black text-blue-500 border border-blue-500 border-solid rounded-full"
                      >
                        <ion-icon name="call-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      {{ selectedUser.phone_no }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12 xl:border-l"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-blue-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Email</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-sm font-black text-blue-500 border border-blue-500 border-solid rounded-full"
                      >
                        <ion-icon name="mail-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      {{ selectedUser.email }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-blue-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Employment Type</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-sm font-black text-blue-500 border border-blue-500 border-solid rounded-full"
                      >
                        <ion-icon name="briefcase-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      {{
                        selectedUser.profile
                          ? selectedUser.profile.emp_type
                          : "---"
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12 xl:border-l"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-blue-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Loan Facility</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-sm font-black text-blue-500 border border-blue-500 border-solid rounded-full"
                      >
                        <ion-icon name="calculator-outline"></ion-icon>
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      {{
                        selectedUser.profile
                          ? selectedUser.profile.current_loan_facility
                          : "---"
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-green-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Total Loan Amount</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 font-black text-green-500 border-2 border-green-500 border-solid rounded-full"
                      >
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">
                      ₦ {{ totalLoanAmount | currency }}
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    class="relative px-8 py-8 border-t border-blue-200 xl:px-12 xl:border-l"
                  >
                    <!-- <span class="absolute left-0 block h-4 ml-3 bg-orange-500 xl:ml-5" style="width: 3px"></span> -->
                    <div class="flex items-center justify-between mb-4">
                      <div class="text-xs">Current Loan</div>
                      <div
                        class="flex items-center justify-center w-6 h-6 font-black text-green-500 border-2 border-green-500 border-solid rounded-full"
                      >
                        ₦
                      </div>
                    </div>
                    <div class="text-xs font-medium">₦ 0.00</div>
                  </div>
                </div>
              </div>

              <!-- <h2 class="mb-5 text-lg font-bold">Recent Transactions</h2>

              <div class="border border-blue-200 border-solid">
                <datatable :data="transactions" :columns="transactionColumns" />
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center"
      ref="confirmDeleteModal"
    >
      <div class="mb-10">
        <div class="mb-10 text-xl font-bold">
          Delete This User?
        </div>

        <div class="text-sm font-light text-gray-500">
          <p class="mb-2">
            This user will be permanently removed from the platform.
          </p>
        </div>
      </div>

      <template v-if="getFormError(deleteForm)">
        <div class="mb-10 alert alert-red-soft">
          <span class="alert icon">!</span>
          <span>{{ getFormError(deleteForm) }}</span>
        </div>
      </template>

      <div class="flex items-center justify-center">
        <button
          type="button"
          class="text-white bg-blue-500 button hover:bg-blue-600"
          @click.prevent="$refs.confirmDeleteModal.close"
          :disabled="deleteForm.loading"
        >
          Cancel
        </button>

        <button
          type="button"
          class="ml-3 text-white bg-red-500 button hover:bg-red-600"
          @click.prevent="deleteUser"
          :disabled="deleteForm.loading"
        >
          <span v-if="deleteForm.loading">Deleting...</span>
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="deleteModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="mb-4 text-lg font-bold">
        Delete!
      </div>
      <div class="mb-10 text-xs">
        User Deleted Successfully.
      </div>

      <button
        type="button"
        class="text-white bg-blue-500 button hover:bg-blue-600"
        @click.prevent="$refs.deleteModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      url: `${this.$baseurl}/admin/personal/users/inactive-users`,
      columns: [
        {
          name: "name",
          th: "Name",
          render: (user) => user.name + " " + user.last_name,
        },
        {
          name: "company",
          th: "Company",
          render: (user, company) => company?.name || "----",
        },
        {
          name: "email",
          th: "Email Address",
        },
        {
          name: "progress",
          th: "Progress",
          render: (user) => {
            return this.progressIcons(user);
          },
        },
        {
          name: "status",
          th: "Status",
          render: (user, status) => {
            return this.statusBadge(status, user);
          },
        },
        {
          name: "admins",
          th: "Assigned To",
          render: (user) => {
            return this.assignedAdmin(user);
          },
        },
        {
          name: "created_at",
          th: "Date Registered",
          render: (user, created_at) => moment(created_at).fromNow(),
        },
      ],
      filters: [
        { title: "all", name: "all" },
        { title: "activated", name: "activated" },
        { title: "inactivated", name: "inactivated" },
      ],
      deleteForm: this.$options.basicForm([]),
      selectedUser: null,
      users: this.$options.resource([]),
      approvalForm: this.$options.basicForm([
        "credit_limit",
        "loan_limit",
        "interest_rate",
        "billing_date",
        "payment_date",
      ]),
      loading: null,
      profileApprovals: [],
      searchQuery: "",
      transactionColumns: [
        {
          name: "created_at",
          th: "Time",
          render: (transaction, created_at) => {
            return (
              this.$options.filters.dateFormat(created_at, "M dd") +
              " at " +
              this.$options.filters.dateFormat(created_at, "h:ia")
            );
          },
        },
        {
          name: "status",
          th: "Status",
          render: (transaction, status) => {
            switch (status) {
              case "approved":
                return `<div class="inline-block px-3 py-2 text-xs text-green-500 bg-green-100 border border-green-500 rounded-sm">
                    Confirmed
                  </div>`;
              case "declined":
                return `<div class="inline-block px-3 py-2 text-xs text-orange-500 bg-orange-100 border border-orange-500 rounded-sm">
                    Pending
                  </div>`;
              case "paid":
                return `<div class="inline-block px-3 py-2 text-xs text-green-500 bg-green-100 border border-green-500 rounded-sm">
                    Confirmed
                  </div>`;
              case "pending":
              default:
                return `<div class="inline-block px-3 py-2 text-xs text-orange-500 bg-orange-100 border border-orange-500 rounded-sm">
                    Pending
                  </div>`;
            }
          },
        },
        {
          align: "right",
          name: "loan_amount",
          th: "Amount",
          render: (transaction, amount) =>
            `₦ ${this.$options.filters.currency(amount)}`,
        },
      ],
    };
  },
  computed: {
    ...mapState(["banks"]),

    userFirstName() {
      return this.selectedUser?.name.split(" ")[0];
    },
    userProfileActivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "activate") ||
        this.selectedUser?.profile?.status === "activated"
      );
    },
    userProfileCompleted() {
      return this.selectedUser?.profile;
    },
    userProfileDeactivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "deactivate") ||
        this.selectedUser?.profile?.status === "deactivated"
      );
    },
    userApprovalPending() {
      return this.selectedUser?.profile?.status === "pending";
    },
    userApproval() {
      return this.profileApprovals.find(
        (approval) => approval.id === this.selectedUser.id
      );
    },
    userApprovalCompleted() {
      return this.userApproval?.response?.data?.status;
    },
    userApprovalError() {
      const error = this.userApproval?.error;
      if (!error) {
        return null;
      }

      if (error?.toString().match(/Error: Network Error/i)) {
        return "Please check your internet connection";
      }

      if (error?.response?.data?.message) {
        return error?.response?.data?.message;
      }

      return "Please try again later.";
    },
    userApprovalLoading() {
      return this.userApproval?.loading === true;
    },
    isOpen() {
      return !!this.selectedUser;
    },
    position() {
      return this.selectedUser?.profile?.position;
    },
    totalLoanAmount() {
      return (
        this.transactions &&
        this.transactions.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
    transactions() {
      return this.selectedUser?.loan;
    },
  },
  methods: {
    activateProfile() {
      this.$refs.approvalConfirmationModal.open();
    },
    async approve() {
      if (!this.validateForm(this.approvalForm)) {
        return false;
      }

      const selectedUser = this.selectedUser;

      this.profileApprovals = this.profileApprovals.filter(
        (approval) => approval.id != selectedUser.id
      );
      this.profileApprovals.push({
        id: selectedUser.id,
        action: "activate",
        loading: true,
        response: null,
        error: null,
      });

      this.approvalForm.loading = true;

      await this.$post({
        url: `${this.$baseurl}/staff/profile/activate`,
        data: {
          user_id: selectedUser.id,
          ...this.getFormData(this.approvalForm),
        },
        headers: this.headers,
        success: (response) => {
          this.profileApprovals.map((approval) => {
            if (approval.id === selectedUser.id) {
              approval.loading = false;
              approval.response = response;
            }
            return approval;
          });
          this.getUsers();
          this.approvalForm = this.resetForm(this.approvalForm);
          this.$refs.approvalConfirmationModal.close();
        },
        error: (error) => {
          this.approvalForm.error = error;

          this.profileApprovals.map((approval) => {
            if (approval.id === selectedUser.id) {
              approval.loading = false;
              approval.error = error;
            }
            return approval;
          });
        },
      });

      this.approvalForm.loading = false;
      // this.profileApprovals.filter( approval => approval.id != selectedUser.id );
    },
    async deactivateProfile() {
      const selectedUser = this.selectedUser;

      this.profileApprovals = this.profileApprovals.filter(
        (approval) => approval.id != selectedUser.id
      );
      this.profileApprovals.push({
        id: selectedUser.id,
        action: "deactivate",
        loading: true,
        response: null,
        error: null,
      });

      await this.$post({
        url: `${this.$baseurl}/staff/profile/deactivate`,
        data: {
          user_id: selectedUser.id,
        },
        headers: this.headers,
        success: (response) => {
          this.profileApprovals.map((approval) => {
            if (approval.id === selectedUser.id) {
              approval.loading = false;
              approval.response = response;
            }
            return approval;
          });
          this.getUsers();
        },
        error: (error) => {
          this.profileApprovals.map((approval) => {
            if (approval.id === selectedUser.id) {
              approval.loading = false;
              approval.error = error;
            }
            return approval;
          });
        },
      });

      // this.profileApprovals.filter( approval => approval.id != selectedUser.id );
    },
    confirmDelete() {
      this.$refs.confirmDeleteModal.open();
    },
    async deleteUser() {
      this.deleteForm.error = false;
      this.deleteForm.loading = true;
      await this.sendRequest("admin.users.delete", this.selectedUser.id, {
        success: () => {
          this.$refs.confirmDeleteModal.close();
          this.$refs.deleteModal.open();
          this.selectedUser = null;
          this.getUsers();
        },
        error: (error) => {
          this.deleteForm.error = error;
        },
      });
      this.deleteForm.loading = false;
    },
    click(selectedUser) {
      this.selectedUser =
        this.selectedUser === selectedUser ? null : selectedUser;
      this.$refs.table.renderData();
    },
    tableRowClassName(row) {
      return row.id === this.selectedUser?.id ? "selected" : "";
    },
    async getUsers() {
      this.users.loading = true;

      await this.sendRequest("admin.users.status", "inactive", {
        success: (response) => {
          if (this.isSuperRisk) {
            this.users.data = response.data.users.filter((el) => {
              return el.profile.status == "verified";
            });
          } else {
            this.users.data = response.data.users;
          }
        },
        error: (error) => {
          this.users.error = error;
        },
      });

      this.users.loading = false;
    },
  },
};
</script>
